<template>
  <div class="fixed-content">
    <div
      class="
        container-fluid
        bg-custom
        pt-1
        pb-1
        bg-white
        border-bottom-2
        bc-warning
      "
    >
      <div class="container-nav-saik">
        <div class="img-logo-header">
          <router-link to="/">
            <img src="@/assets/images/papua/papua-logo.png" alt />
          </router-link>
        </div>
        <b-nav-item-dropdown class="nav-top-borderless bantuan-res" right>
          <template v-slot:button-content>
            <span class="btn-top font-weight-5 font-14">
              <span class="img-top-img">
                <img src="@/assets/images/papua/icons/question.png" alt />
              </span>
              Bantuan
            </span>
          </template>
          <!-- <b-dropdown-item href="/bantuan/istilahsaik">Istilah dalam SAIK</b-dropdown-item> -->
          <b-dropdown-item href="#">Troubleshoot</b-dropdown-item>
          <b-dropdown-item href="/bantuan/faq">FAQ</b-dropdown-item>
          <b-dropdown-item href="/bantuan/dokumentasi"
            >Dokumentasi</b-dropdown-item
          >
          <b-dropdown-item href="/bantuan/tentangkami"
            >Tentang Kami</b-dropdown-item
          >
        </b-nav-item-dropdown>

        <b-nav-item
          class="nav-top"
          href="https://admin.siopapua.papua.go.id/siopapua-login"
        >
          <b-navbar-brand
            class="mr-0 text-primary pt-0 pb-0 font-14 font-weight-5"
            href="https://admin.siopapua.papua.go.id/siopapua-login"
            target=""
            >Login</b-navbar-brand
          >
        </b-nav-item>
      </div>
    </div>

    <div
      class="container-fluid shadow-responsive header-scroll"
      v-bind:class="{ activeScroll: scrollON }"
    >
      <div class="container-nav-saik">
        <div class="margin-auto width-100 bottom-navigation">
          <b-navbar toggleable="lg" type="dark">
            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
              <!-- Right aligned nav items -->
              <b-navbar-nav class="ml-auto sub-nav" right>
                <b-nav-item to="/" exact>
                  <b-navbar-brand class="mr-2 ml-2">Home</b-navbar-brand>
                </b-nav-item>
                <b-nav-item
                  to="/berita"
                  :class="{
                    'router-link-isactive': subIsActive(['/berita/detail/:id']),
                  }"
                  exact
                >
                  <b-navbar-brand class="mr-2 ml-2">Berita</b-navbar-brand>
                </b-nav-item>

                <!-- <b-nav-item
                  class
                  to="#"
                  :class="{'router-link-isactive': subIsActive(['/berita/detail/:id'])}"
                  exact
                >
                  <b-navbar-brand to="/berita" class="mr-2 ml-2">Berita</b-navbar-brand>
                </b-nav-item> -->

                <b-nav-item-dropdown
                  class="mr-2 ml-2 dropdown-style"
                  to="/data-pokok"
                  text="Data Pokok"
                  :class="{
                    'router-link-isactive': subIsActive(['/data-pokok/']),
                  }"
                  exact
                >
                  <b-dropdown-item href="/data-pokok/data-kependudukan/"
                    >Data Kependudukan</b-dropdown-item
                  >
                  <b-dropdown-item href="/data-pokok/data-kesehatan/"
                    >Data Kesehatan</b-dropdown-item
                  >
                  <b-dropdown-item href="/data-pokok/data-pendidikan/"
                    >Data Pendidikan</b-dropdown-item
                  >
                  <b-dropdown-item href="/data-pokok/data-bantuan-sosial/"
                    >Data Bantuan Sosial</b-dropdown-item
                  >
                  <b-dropdown-item href="#">Data Keuangan</b-dropdown-item>
                </b-nav-item-dropdown>
                <!-- <b-nav-item-dropdown
                  class="mr-2 ml-2 dropdown-style"
                  to="/data-pokok"
                  text="Progres Data"
                  :class="{
                    'router-link-isactive': subIsActive(['/progress-data/']),
                  }"
                  exact
                >
                  <b-dropdown-item href="#"
                    >Progres Data Kependudukan</b-dropdown-item
                  >
                  <b-dropdown-item href="#"
                    >Progres Data Kesehatan</b-dropdown-item
                  >
                  <b-dropdown-item href="#"
                    >Progres Data Pendidikan</b-dropdown-item
                  >
                  <b-dropdown-item href="#"
                    >Progres Data Bantuan Sosial</b-dropdown-item
                  >
                  <b-dropdown-item href="#"
                    >Progres Data Keuangan</b-dropdown-item
                  >
                </b-nav-item-dropdown> -->
                <b-nav-item
                  class
                  to="/repository"
                  :class="{
                    'router-link-isactive': subIsActive(['/repository']),
                  }"
                  exact
                >
                  <b-navbar-brand class="mr-2 ml-2">Repository</b-navbar-brand>
                </b-nav-item>
                <b-nav-item
                  class
                  to="/potensi-kampung"
                  :class="{
                    'router-link-isactive': subIsActive(['/potensi-kampung']),
                  }"
                  exact
                >
                  <b-navbar-brand class="mr-2 ml-2"
                    >Potensi Kampung</b-navbar-brand
                  >
                </b-nav-item>
                <b-nav-item-dropdown
                  class="mr-2 ml-2 dropdown-style"
                  to="/data-pokok"
                  text="Layanan"
                  :class="{
                    'router-link-isactive': subIsActive(['/layanan/']),
                  }"
                  exact
                >
                  <b-dropdown-item href="/layanan/administrasi-kependudukan"
                    >Administrasi Kependudukan</b-dropdown-item
                  >
                  <b-dropdown-item href="#">Mutasi</b-dropdown-item>
                  <b-dropdown-item href="#">Pertahanan</b-dropdown-item>
                  <b-dropdown-item
                    href="https://admin.siopapua.papua.go.id/admin/letter/new"
                    target=""
                    >Surat Menyurat</b-dropdown-item
                  >
                </b-nav-item-dropdown>
              </b-navbar-nav>
            </b-collapse>
          </b-navbar>
        </div>
      </div>
    </div>
    <!-- <b-modal id="modal-login" hide-footer title>
      <div class="row pad-10">
        <div class="col-md-12 flex mb-40">
          <span class="d-flex w-50 font-22 text-primary font-weight-5">Login</span>
          <span
            class="d-flex w-50 justify-end text-right mt-2 text-warning cursor-pointer font-weight-5"
          >Daftar</span>
        </div>
        <div class="col-md-12" v-show="EmailError">
          <span
            class="d-block text-center text-danger font-weight-6 margin-auto font-16 mb-10"
          >Maaf Email Anda Belum Terdaftar</span>
        </div>
        <div class="col-md-12">
          <form action="#">
            <div class="form-group">
              <label class="font-weight-2 text-primary" for="inputEmail">Email address</label>
              <input
                type="email"
                class="form-control"
                name="emailAddress"
                v-model="email"
                v-validate="'required'"
                aria-describedby="emailHelp"
                placeholder="Masukan email anda"
              />
              <small id="emailHelp" class="form-text text-muted">Exp: muammar@gmail.com</small>
              <span class="eror text-danger font-10">{{errors.first('emailAddress')}}</span>
            </div>
            <div class="form-group">
              <label class="font-weight-2 text-primary" for="password">Password</label>
              <input
                type="password"
                name="password"
                class="form-control"
                v-model="password"
                v-validate="'required'"
                aria-describedby="password"
                placeholder="Masukan Password"
              />
              <span class="eror text-danger font-10">{{errors.first('password')}}</span>
            </div>
            <p class="font-12">
              Lupa Password ?
              <span class="font-weight-6 cursor-pointer text-warning">Klik disini!</span>
            </p>
            <div class="form-group mt-34 text-center">
              <button
                class="btn pad-10 mb-15 d-block margin-auto font-weight-6 font-16 bg-warning width-80 text-white"
                @click.prevent="submit()"
              >Login</button>
              <span class="d-block mb-15 font-weight-5 text-primary">Or login with</span>

              <GoogleLogin
                class="flex justify-center"
                :params="params"
                :renderParams="renderParams"
                :onSuccess="onSuccess"
              ></GoogleLogin>
            </div>
          </form>
        </div>
      </div>
    </b-modal> -->
  </div>
</template>
<script>
export default {
  name: "Header",
  components: {
    // GoogleLogin
  },
  data() {
    return {
      windowTop: 0,
      scrollON: false,
    };
  },
  created() {},
  mounted() {
    window.addEventListener("scroll", this.onScroll);
  },
  methods: {
    onScroll() {
      this.windowTop =
        window.top.scrollY; /* or: e.target.documentElement.scrollTop */
      if (this.windowTop <= 0) {
        this.scrollON = false;
      } else if (this.windowTop >= 10) {
        this.scrollON = true;
      }
    },
    subIsActive(input) {
      const paths = Array.isArray(input) ? input : [input];
      return paths.some((path) => {
        return this.$route.path.indexOf(path) === 0; // current path starts with this path string
      });
    },
  },
};
</script>
